import DesktopWindowsRoundedIcon from '@material-ui/icons/DesktopWindowsRounded';
import CategoryRoundedIcon from '@material-ui/icons/CategoryRounded';
import Keyboard from '@material-ui/icons/Keyboard';
import ComputerRoundedIcon from '@material-ui/icons/ComputerRounded';
import Reports from '@material-ui/icons/BarChartSharp';
import EmojiPeopleRoundedIcon from '@material-ui/icons/EmojiPeopleRounded';
import WbIncandescentRoundedIcon from '@material-ui/icons/WbIncandescentRounded';
import TabRoundedIcon from '@material-ui/icons/TabRounded';
import Calulator from '@mui/icons-material/Calculate';
import TabletAndroidRoundedIcon from '@material-ui/icons/TabletAndroidRounded';


export const SidebarData0= [
    {
        icon:DesktopWindowsRoundedIcon,
        heading:"आय डेअरी ग्रो",
        heading2:"आय डेअरी ग्रो",
        pathname:"/idairygrow"
    },
]

export const SidebarData1 = [
    {
        icon:DesktopWindowsRoundedIcon,
        heading:"मास्टर माहिती",
        heading2:"मास्टर माहिती",
        pathname:"/Dashboard"
    },
    {
        icon:Keyboard,
        heading:"दैनंदिन व्यवहार",
        heading2:"दैनंदिन व्यवहार",
        pathname:"/Transections"
    },
    {
        icon:CategoryRoundedIcon,
        heading:"इतर व्हाऊचर",
        heading2:"इतर व्हाऊचर",
        pathname:"/ivouchers"
    },
    {
        icon:Calulator,
        heading:"ऑडिटिंग विभाग",
        heading2:"ऑडिटिंग विभाग",
        pathname:"/audit"
    },
    {
        icon:Reports,
        heading:"रिपोर्ट्स",
        heading2:"रिपोर्ट्स",
        pathname:"/reports"
    }
]


export const SidebarData2 = [
    {
        icon:EmojiPeopleRoundedIcon,
        heading:"इतर सुविधा",
        heading2:"इतर सुविधा",
        pathname:"/extra"
    }
    ,
    {
        icon:WbIncandescentRoundedIcon,
        heading:"मदत",
        heading2:"मदत",
        pathname:"/help"
    }
]
export const SidebarData3= [

    {
        icon:TabRoundedIcon,
        heading:"रजिस्ट्रेशन",
        heading2:"रजिस्ट्रेशन",
        pathname:"/regi"
    }
   
]