import React, { useContext, useEffect, useRef, useState } from 'react'
import './Terijpatrak.css'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import LatoItalicFont from "../../Fonts/Baloo2-VariableFont_wght.ttf";
import Logo from "../../imgs/dairylogo.png";
import back from "../../imgs/backexam.png";
import { useNavigate } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Language, YoutubeSearchedForOutlined } from '@material-ui/icons';
import khadya from "../../imgs/khadya.png";
import cash from "../../imgs/cash.png";
import { UilEditAlt, UilEye, UilSetting } from '@iconscout/react-unicons'



const Terijpatrak = (props) => {
    const [input, setInput] = useState({ date1: new Date().toISOString().substr(0, 10), date2: new Date().toISOString().substr(0, 10), kno: "", kname: "", lastkhadya: "", lastadv: "", lastdate: "", dno: "", name: "", khname: "", searchsank: "" });
    const inputRefs = useRef([]);
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [DataSourcekh, setDataSourcekh] = useState([]);
    const [DataSourceadv, setDataSourceadv] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [print, setprint] = useState(false);
    const cellRefs = useRef([]);
    const [value, setValue] = useState('');
    const [partyData, setPartydata] = useState([]);
    const [PreviousAmt, setPreviousAmt] = useState();
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [customerData, setCustomerData] = useState([]);
    const [khrtype, setKhrtype] = useState("khpur");
    const navigate = useNavigate();
    const refprint = useRef(null);
    const [khnames, setKhnames] = useState([]);
    const refprintclose = useRef(null);
    const [mobileusers, setMobileusers] = useState([]);
    const [wait, setWait] = useState(false);

    const handlertype = (type) => {
        setKhrtype(type)
    }

    useEffect(() => {
        setDataSource([]);
    }, [khrtype])

    const handleprintclose = () => {
        refprintclose.current.click();
    }



    function handleEnterKey(event, currentIndex) {

        if (event.keyCode === 8) {
            if (event.target.name === "time") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    time: '',
                }));
            }
            else if (event.target.name === "type") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    type: '',
                }));
            }
        }

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            if (currentIndex === 2) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    }

    const filteData = (e) => {
        const searchValue = e.target.value.trim();

        if (searchValue !== "") {
            setValue(searchValue);
            const filtertable = DataSource.filter(o =>
                String(o.kno) === searchValue // Check for exact match with kno
            );

            setTableFilter([...filtertable]);
        } else {
            setValue(searchValue);
            setTableFilter([...DataSource]);
        }

    };

    const filteDataold = (e) => {
        if (e.target.value != "") {
            setValue(e.target.value);
            const filtertable = DataSource.filter(o => Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
            ));

            setTableFilter([...filtertable]);
        }
        else {
            setValue(e.target.value);
            setTableFilter([...DataSource]);
        }
    }
    const showprint = () => {
        if (DataSource && DataSource.length > 0) {
            localStorage.setItem("date1print", input.date1);
            localStorage.setItem("date2print", input.date2);
          
            const url = "/terijpatrakprint";
            window.open(url, '_blank');
        }
        else {
            notifyerror("या कालावधीची माहिती उपलब्ध नाही!");
        }
    }


    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));

        if (name === 'date2') {
            // Check if date2 is more than one year ahead of date1
            const date1 = new Date(input.date1);
            const date2 = new Date(valuecurrent);

            // Calculate the difference in days between date1 and date2
            const differenceInDays = (date2 - date1) / (1000 * 60 * 60 * 24);

            if (differenceInDays <= 30) {
                setInput((prevInput) => ({ ...prevInput, [name]: valuecurrent }));
            } else {
                notifyerror("रिपोर्ट लिमिट - 1 महिना");
                setInput((prevInput) => ({ ...prevInput, [name]: new Date().toISOString().substr(0, 10) }));
            }
        }
    }

    const getterijpatrak = () => {
        setValue("");
        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let landingphp = "";
        // console.log(khrtype);

        landingphp = "getterijpatrak.php";
        let rawData = {
            date1: input.date1,
            date2: input.date2,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
        }
        rawData = JSON.stringify(rawData)
        console.log(rawData);
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post(`https://idairy.co.in/dairy/php/cust/${landingphp}`, formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            console.log(response.data)
            if (response.data && response.data.length > 0) {
                setDataSource(response.data);

                // if (khrtype === "dreport") {

                //     setPreviousAmt(response.data[0].
                //         previousamt
                //     );
                // }

                setWait(false);
            }
            else {
                notifyerror("माहिती उपलब्ध नाही!");
                setDataSource([]);
                setWait(false);
            }
        }).catch(err => {
            console.log(err);
            setWait(false);
        });
    }


    const getudharidetails = () => {

    }

    function formatDate(inputDate) {
        // Ensure inputDate is in 'yyyy-mm-dd' format
        if (inputDate) {
            const parts = inputDate.split('-');
            if (parts.length !== 3) {
                return ''; // Invalid date format
            }

            // Convert month number to month name in English
            const months = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ];

            const year = parts[0];
            const month = parseInt(parts[1]) - 1; // Subtract 1 because months are 0-based
            const day = parts[2];

            return `${day}-${months[month]}`;
            // -${year}`;

        }
    }

    function formatDatefull(inputDate) {
        // Ensure inputDate is in 'yyyy-mm-dd' format
        if (inputDate) {
            const parts = inputDate.split('-');
            if (parts.length !== 3) {
                return ''; // Invalid date format
            }

            // Convert month number to month name in English
            const months = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ];

            const year = parts[0];
            const month = parseInt(parts[1]) - 1; // Subtract 1 because months are 0-based
            const day = parts[2];

            return `${day}-${months[month]} -${year}`;


        }
    }

    const showDetails = (kno, cname, lastadv, lastkhadya, lastdate) => {

        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let landingphp = "";

        landingphp = "getudharidetails2.php";

        let rawData = {
            date1: input.date1,
            date2: input.date2,
            kno: kno,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            billtype: localStorage.getItem("bill10type")
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post(`https://idairy.co.in/dairy/php/cust/${landingphp}`, formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {

            if (response.data && response.data.length > 0) {
                refprint.current.click();
                response.data && response.data.map((user, index) =>
                    setInput((prevInputSettings) => ({
                        ...prevInputSettings,
                        kno: kno,
                        kname: cname,
                        lastadv: user.lastadv,
                        lastkhadya: user.lastkhadya,
                        lastdate: lastdate
                    }))
                )
                setWait(false);
                getkhsalelatest(kno);
            }
            else {
                notifyerror("माहिती उपलब्ध नाही!");
                setDataSource([]);
                setWait(false);
            }
        }).catch(err => {
            console.log(err);
            setWait(false);
        });
    }

    const getkhsalelatest = (kno) => {

        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let landingphp = "";

        landingphp = "getkhsalelatest.php";

        let rawData = {
            date1: input.date1,
            date2: input.date2,
            kno: kno,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            billtype: localStorage.getItem("bill10type")
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post(`https://idairy.co.in/dairy/php/cust/${landingphp}`, formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {

            if (response.data && response.data.length > 0) {
                setDataSourcekh(response.data);
                setWait(false);
            }
            else {

                setDataSourcekh([]);
                setWait(false);
            }
            getadvlatest(kno);
        }).catch(err => {

            setWait(false);
        });
    }

    const getadvlatest = (kno) => {

        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let landingphp = "";

        landingphp = "getadvlatest.php";

        let rawData = {
            date1: input.date1,
            date2: input.date2,
            kno: kno,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            billtype: localStorage.getItem("bill10type")
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post(`https://idairy.co.in/dairy/php/cust/${landingphp}`, formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {

            if (response.data && response.data.length > 0) {
                //   console.log(response.data);
                setDataSourceadv(response.data);
                setWait(false);
            }
            else {

                setDataSourceadv([]);
                setWait(false);
            }
        }).catch(err => {
            console.log(err);
            setWait(false);
        });
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };


    useEffect(() => {
        if (input.dno === "") {
            setInput(prevInput => ({
                ...prevInput,
                name: "",
            }))
        }
        else {

            let custlisttemp = JSON.parse(localStorage.getItem("auditlist"));
            for (let i = 0; i < custlisttemp.length; i++) {
                setInput(prevInput => ({
                    ...prevInput,
                    name: ''
                }));
                const user = custlisttemp[i];
                if (user.kno === input.dno) {
                    setInput(prevInput => ({
                        ...prevInput,
                        name: user.name,
                    }));
                    break; // Exit the loop once the condition is met
                }
            }

        }
    }, [input.dno])

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refprint}
                data-bs-target="#printinput"
            >
            </button>

            <div className="sanklanmain">
                <div className="firstrowsank">
                    <img onClick={props.visibility} className='back' src={back} alt="" />
                    <div className="colmain">
                        <div className="firstrow2">
                            <div className="rowbill">
                                <>
                                    <div className="mb-2">
                                        <span className='subheadsank'>दिनांक</span>
                                        <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
                                    </div>

                                    <span className='subheadbill'>ते</span>

                                    <div className="mb-2">
                                        <span className='subheadsank'>दिनांक</span>
                                        <input onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
                                    </div>
                                </>

                                <div className="mb-2">
                                    {
                                        wait ?
                                            <button ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">लोडिंग..</button>
                                            :
                                            <button onClick={getterijpatrak} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">रिपोर्ट पहा</button>

                                    }
                                    <button onClick={showprint} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">प्रिंट</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="firstrow2">
                    <div class="table-containerbill">

                        <table id="myTablebill" className="table table-bordered">
                            <thead>
                                <tr>
                                    <th className='thsmall' scope="col">खाते क्रमांक</th>
                                    <th className='thsmall' scope="col">खात्याचे नाव</th>
                                    <th className='thsmall' scope="col">आरंभी शिल्लक</th>
                                    <th className='thsmall' scope="col">जमा</th>
                                    <th className='thsmall' scope="col">नावे</th>
                                    <th className='thsmall' scope="col">अखेरची शिल्लक</th>
                                </tr>
                            </thead>
                            <tbody>
                                {value.length > 0 ? tableFilter.map((user, index) =>
                                    <tr key={index}>
                                        <td className='thsmallcustkhr'>{user.kno}</td>
                                        <td className='thsmallcustlarge'>{user.name}</td>
                                        <td className='thsmallcustkhr'>{user.opening_balance}</td>
                                        <td className='thsmallcustkhr'>{user.जमा}</td>
                                        <td className='thsmallcustkhr'>{user.नावे}</td>
                                        <td className='thsmallcustkhr'>{user.closing_balance}</td>

                                    </tr>
                                )
                                    :
                                    DataSource && DataSource.map((user, index) =>
                                        <tr key={index}>
                                            <td className='thsmallcustkhr'>{user.kno}</td>
                                            <td className='thsmallcustlarge'>{user.name}</td>
                                            <td className='thsmallcustkhr'>{user.opening_balance}</td>
                                            <td className='thsmallcustkhr'>{user.जमा}</td>
                                            <td className='thsmallcustkhr'>{user.नावे}</td>
                                            <td className='thsmallcustkhr'>{user.closing_balance}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                            <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                <td className='thsmallcust'>एकूण</td>
                                <td className='thsmallcust'>-</td>
                                <td className='thsmallcust'>
                                    {value.length > 0
                                        ? tableFilter.reduce((total, user) => total + parseFloat(user.opening_balance), 0).toFixed(2)
                                        : DataSource.reduce((total, user) => total + parseFloat(user.opening_balance), 0).toFixed(2)}
                                </td>
                                <td className='thsmallcust'>
                                    {value.length > 0
                                        ? tableFilter.reduce((total, user) => total + parseFloat(user.जमा), 0).toFixed(2)
                                        : DataSource.reduce((total, user) => total + parseFloat(user.जमा), 0).toFixed(2)}
                                </td>
                                <td className='thsmallcust'>
                                    {value.length > 0
                                        ? tableFilter.reduce((total, user) => total + parseFloat(user.नावे), 0).toFixed(2)
                                        : DataSource.reduce((total, user) => total + parseFloat(user.नावे), 0).toFixed(2)}
                                </td>
                                <td className='thsmallcust'>
                                    {value.length > 0
                                        ? tableFilter.reduce((total, user) => total + parseFloat(user.closing_balance), 0).toFixed(2)
                                        : DataSource.reduce((total, user) => total + parseFloat(user.closing_balance), 0).toFixed(2)}
                                </td>
                            </tr>

                        </table>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Terijpatrak
